.has-children-centered {
    align-items: center;
}
.has-children-pulled-left {
    align-items: flex-start;
}
.has-children-pulled-right {
    align-items: flex-end;
}

.has-children-vertically-centered {
    justify-content: center;
}
.has-children-pulled-up {
    justify-content: flex-start;
}
.has-children-pulled-down {
    justify-content: flex-end;
}

.has-children-evenly-spaced {
    justify-content: space-evenly;
}
.has-children-pushed-apart {
    justify-content: space-between;
}

.is-flex-column {
    display: flex;
    flex-direction: column;
}

.has-wrapping-children {
    flex-wrap: wrap;
}
