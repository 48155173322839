@import url('https://fonts.googleapis.com/css2?family=Archivo:ital,wght@0,400;0,500;0,600;0,700;1,500&display=swap');

.event-desktop-ticket {
    box-sizing: content-box;
    height: 0;
    width: 100%;
    overflow: hidden;
    padding: 0 0 50%;
    position: relative;
    border: 2px solid #4a4a4a;
    font-family: 'Archivo', sans-serif;
    max-width: 950px;
    background: white;

    &--outer-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &--top-banner {
        width: 100%;
        height: 30px;
        position: absolute;
        top: 0;

        &--left {
            display: inline-block;
            padding: 0 2%;
            height: 100%;
            line-height: 30px;
        }
        &--right {
            font-size: 1.5em;
            position: absolute;
            top: 0;
            right: 5px;
            line-height: 30px;
        }
    }

    &--bottom-wrapper {
        width: 100%;
        height: calc(100% - 30px);
        position: relative;
        top:30px;
        box-sizing: border-box;
    }

    &--qr-code {
        height: 100%;
        display: flex;
        align-items: center;

        & div {
            padding: 20% 0;
            border: 2px solid #000;
            border-width: 0 0 0 2px;
        }
    }

    &--middle {
        &--top {
            height: calc(33% - 15px);
            display: flex;
            align-items: flex-end;
        }
        &--middle {
            height: 34%;
            display: flex;
            align-items: center;

            &--left {
                flex: 0 0 60%;
                padding: 10px;
                border: 1px solid transparent;
                border-radius: 0 15px 15px 0;
                line-height: 1.2;
                height: 100%;

                & span {
                    display: block;
                }
            }
            &--right {
                flex: 0 0 40%;
                align-content: center;
                padding: 5px;
            }
        }
        &--bottom {
            height: calc(33% + 15px);
            display: flex;
            align-items: flex-start;
        }

        &--X-spacer {
            width: 10%;
            height: 100%;
            position: relative;
            overflow: hidden;

            & div {
                content: 'test';
                width: 200%;
                height: 200%;
                top: -50%;
                left: -50%;
                position: absolute;
                transform: rotate(-30deg);
                background-position: 0 0;
                background-repeat: repeat;
                background-size: 25px;
                opacity: 0.25;
            }
        }
    }

    &--title-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    &--subsubtitle {
        font-size: 0.8em;
    }
    &--title {
        line-height: 1.2;
    }
}


.event-mobile-ticket {
    position: relative;
    font-family: 'Archivo', sans-serif;

    &--collapsed {
        width: 100%;
        height: 0;
        overflow: hidden;
        padding: 0 0 50% 0;
        position: relative;
        box-sizing: border-box;

        & > div {
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            //background-image: url('https://kylefritz.org/storage/media_library/0d22fcae-8ebc-4879-a1ff-654393c2d513/3d-printing_thumb.jpg');

            & a {
                display: block;
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                padding: 5px;
                color: white !important;
                background: rgba(0, 0, 0, 0.3);
                text-shadow: 1px 1px 1px #000;
                text-decoration: none !important;
            }

            & * {
                color: inherit;
            }
        }
    }


    &--open {
        height: calc(100vh - 3.25rem + 0.75rem);
        width: 100%;
        color: white;

        & * {
            color: inherit;
        }
    }

    &--image {
        height: 15vh;
    }

    &--bottom {
        text-align: center;

        & > div {
            margin: 0 auto;

            & div {
                padding: 1em;
                position: relative;

                & img {
                    max-width: 60vw;
                    border: 1px solid transparent;
                    border-radius: 1em;
                }

                & p {
                    position: absolute;
                    top:0;
                    left:0;
                    right:0;
                    bottom:0;
                    vertical-align: center;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    & span {
                        font-size: 5em;
                        transform: rotate(-45deg);
                        color: red;
                    }
                }
            }
        }
    }
}
