.gift-card-wrapper {
    max-width: 400px;

    & .is-hidden-mobile .card-image {
        border: 8px solid;
    }
    & .is-hidden-tablet {
        border-bottom-left-radius: initial;
        border-bottom-right-radius: initial;

        & .card-image {
            border: 5px solid;
        }
    }
}

.gift-card-inner {
    border-bottom: 1px dashed grey;
}
