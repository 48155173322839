body .is-primary-on-hover:hover {
    color: $primary !important;
    fill: $primary !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $primary !important;
    }
}
body .is-info-on-hover:hover {
    color: $info !important;
    fill: $info !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $info !important;
    }
}
body .is-success-on-hover:hover {
    color: $success !important;
    fill: $success !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $info !important;
    }
}
body .is-warning-on-hover:hover {
    color: $warning !important;
    fill: $warning !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $warning !important;
    }
}
body .is-danger-on-hover:hover {
    color: $danger !important;
    fill: $danger !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $danger !important;
    }
}
body .is-light-on-hover:hover {
    color: $light !important;
    fill: $light !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $light !important;
    }
}
body .is-dark-on-hover:hover {
    color: $dark !important;
    fill: $dark !important;

    &.box {
        box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px $dark;
    }
}

.is-underlined-on-hover:hover {
    text-decoration: underline !important;
}

.is-underlined {
    text-decoration: underline;
}
