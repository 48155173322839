.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 0.5rem;
    justify-items: stretch;
    align-items: stretch;
    justify-content: center;
    align-content: start;
}

.grid-horizontal-fill {
    grid-auto-flow: row;
}
.grid-vertical-fill {
    grid-auto-flow: column;
}
.grid-horizontal-fill-dense {
    grid-auto-flow: row dense;
}
.grid-vertical-fill-dense {
    grid-auto-flow: column dense;
}

.grid-place-children-center {
    justify-content: center;
}
.grid-pull-left {
    justify-content: start;
}
.grid-pull-right {
    justify-content: end;
}
.grid-stretch-children {
    justify-content: stretch;
}
.grid-place-children-evenly {
    justify-content: space-evenly;
}
.grid-place-children-at-edges {
    justify-content: space-between;
}

.grid-center-vertically {
    align-content: center;
}
.grid-pull-down {
    align-content: end;
}
.grid-stretch-children-vertically {
    align-content: stretch;
}
.grid-place-children-evenly-vertically {
    align-content: space-evenly;
}
.grid-place-children-at-edges-vertically {
    align-content: space-between;
}

.grid-has-rows {
    grid-template-rows: repeat(12, 1fr);
}
.grid-inline {
    display: inline-grid;
}
.grid-items-vertically-centered {
    align-items: center;
}
.grid-items-top {
    align-items: start;
}
.grid-items-bottom {
    align-items: end;
}
.grid-items-horizontally-centered {
    justify-items: center;
}
.grid-items-left {
    justify-items: start;
}
.grid-items-right {
    justify-items: end;
}
.grid-items-centered {
    place-items: center center;
}

.grid-no-gap {
    grid-gap: 0;
}
.grid-tiny-gap {
    grid-gap: 0.10rem;
}
.grid-small-gap {
    grid-gap: 0.25rem;
}
.grid-medium-gap {
    grid-gap: 1rem;
}
.grid-large-gap {
    grid-gap: 2rem;
}


@media screen and (max-width:1023px) {
    .grid {
        grid-template-columns: 1fr 1fr;
    }
}
