.wikimenu {

}

.wikimenu-submenu-title {
    background-image: linear-gradient(to right,rgba(200,204,209,0) 0,#c8ccd1 33%,#c8ccd1 66%,rgba(200,204,209,0) 100%);
    background-position: center bottom;
    background-repeat: no-repeat;
    background-size: 100% 1px;
    color: #54595d;
    border: 0;
}
