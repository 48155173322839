#app {
    position: relative;
    overflow: hidden;
}

[v-cloak] {
    display: none;
}

body .input::placeholder {
    color: #9d9d9d;
}

ol {
    counter-reset: section;
    list-style-type: none !important;

    & li {
        counter-increment: section;
        padding-left: 10px;
        position: relative;
    }
}

ol > li::before {
    content: counters(section, ".") ".";
    font-weight: bold;
    position: absolute;
    left: -20px;
}
.quill ol > li::before {
    content: normal;
    position: absolute;
    left: 0;
}

.list {
    margin-left: 20px;
    list-style-type: disc;

    & li {
        list-style-type: disc;
    }
}
