.quill .ql-editor.ql-blank:before {
    font-style: normal;
    color: #ccc;
    font-size: 15px
}

.quill .ql-editor {
    color: #000000
}

.quill .ql-snow .ql-editor b, .quill .ql-snow .ql-editor h1, .quill .ql-snow .ql-editor h2, .quill .ql-snow .ql-editor h3, .quill .ql-snow .ql-editor h4, .quill .ql-snow .ql-editor h5, .quill .ql-snow .ql-editor h6, .quill .ql-snow .ql-editor p b, .quill .ql-snow .ql-editor p strong, .quill .ql-snow .ql-editor strong {
    font-weight: 700
}

.quill .ql-snow .ql-editor ol, .quill .ql-snow .ql-editor p, .quill .ql-snow .ql-editor ul {
    margin-bottom: 1rem;
}

.quill .ql-snow .ql-editor h1, .quill .ql-snow .ql-editor h2, .quill .ql-snow .ql-editor h3, .quill .ql-snow .ql-editor h4, .quill .ql-snow .ql-editor h5 {
    margin-bottom: 1rem;
    margin-top: 1em;
}

.quill .ql-snow .ql-editor h1 {
    font-size: 2em;
    line-height: 1.25em
}

.quill .ql-snow .ql-editor h2 {
    font-size: 1.66em;
    line-height: 1.25em
}

.quill .ql-snow .ql-editor h3 {
    font-size: 1.33em;
    line-height: 1.25em
}

.quill .ql-snow .ql-editor h4 {
    font-size: 1.25em;
    line-height: 1.25em
}

.quill .ql-snow .ql-editor h5 {
    font-size: 1em;
    line-height: 1.25em
}

.quill .ql-snow .ql-editor pre {
    color: #333;
    padding: 15px;
    overflow: auto;
    background-color: #f6f8fa;
    border-radius: 3px;
    font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, Courier, monospace
}

.quill .ql-snow .ql-editor sup {
    vertical-align: super;
    font-size: smaller
}

.quill .ql-snow .ql-editor sub {
    vertical-align: sub;
    font-size: smaller
}

.quill .ql-snow a {
    color: #3278b8
}

.quill .ql-editor .ql-anchor {
    text-decoration: underline #3278b8
}


.quill .ql-editor blockquote, .quill .ql-editor h1, .quill .ql-editor h2, .quill .ql-editor h3, .quill .ql-editor h4, .quill .ql-editor h5, .quill .ql-editor h6, .quill .ql-editor ol, .quill .ql-editor p, .quill .ql-editor pre, .quill .ql-editor ul {
    margin: 0;
    padding: 0;
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.quill .ql-editor ol, .quill .ql-editor ul {
    padding-left: 1.5em
}

.quill .ql-editor ol > li, .quill .ql-editor ul > li {
    list-style-type: none
}

.quill .ql-editor ul > li:before {
    content: "\2022"
}

.quill .ql-editor li:before {
    display: inline-block;
    white-space: nowrap;
    width: 1.2em
}

.quill .ql-editor li:not(.ql-direction-rtl):before {
    margin-left: -1.5em;
    margin-right: .3em;
    text-align: right
}

.quill .ql-editor li.ql-direction-rtl:before {
    margin-left: .3em;
    margin-right: -1.5em
}

.quill .ql-editor ol li:not(.ql-direction-rtl), .quill .ql-editor ul li:not(.ql-direction-rtl) {
    padding-left: 1.5em
}

.quill .ql-editor ol li.ql-direction-rtl, .quill .ql-editor ul li.ql-direction-rtl {
    padding-right: 1.5em
}

.quill .ql-editor ol li {
    counter-reset: list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
    counter-increment: list-0
}

.quill .ql-editor ol li:before {
    content: counter(list-0, decimal) ". "
}

.quill .ql-editor ol li.ql-indent-1 {
    counter-increment: list-1
}

.quill .ql-editor ol li.ql-indent-1:before {
    content: counter(list-1, lower-alpha) ". "
}

.quill .ql-editor ol li.ql-indent-1 {
    counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.quill .ql-editor ol li.ql-indent-2 {
    counter-increment: list-2
}

.quill .ql-editor ol li.ql-indent-2:before {
    content: counter(list-2, lower-roman) ". "
}

.quill .ql-editor ol li.ql-indent-2 {
    counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9
}

.quill .ql-editor ol li.ql-indent-3 {
    counter-increment: list-3
}

.quill .ql-editor ol li.ql-indent-3:before {
    content: counter(list-3, decimal) ". "
}

.quill .ql-editor ol li.ql-indent-3 {
    counter-reset: list-4 list-5 list-6 list-7 list-8 list-9
}

.quill .ql-editor ol li.ql-indent-4 {
    counter-increment: list-4
}

.quill .ql-editor ol li.ql-indent-4:before {
    content: counter(list-4, lower-alpha) ". "
}

.quill .ql-editor ol li.ql-indent-4 {
    counter-reset: list-5 list-6 list-7 list-8 list-9
}

.quill .ql-editor ol li.ql-indent-5 {
    counter-increment: list-5
}

.quill .ql-editor ol li.ql-indent-5:before {
    content: counter(list-5, lower-roman) ". "
}

.quill .ql-editor ol li.ql-indent-5 {
    counter-reset: list-6 list-7 list-8 list-9
}

.quill .ql-editor ol li.ql-indent-6 {
    counter-increment: list-6
}

.quill .ql-editor ol li.ql-indent-6:before {
    content: counter(list-6, decimal) ". "
}

.quill .ql-editor ol li.ql-indent-6 {
    counter-reset: list-7 list-8 list-9
}

.quill .ql-editor ol li.ql-indent-7 {
    counter-increment: list-7
}

.quill .ql-editor ol li.ql-indent-7:before {
    content: counter(list-7, lower-alpha) ". "
}

.quill .ql-editor ol li.ql-indent-7 {
    counter-reset: list-8 list-9
}

.quill .ql-editor ol li.ql-indent-8 {
    counter-increment: list-8
}

.quill .ql-editor ol li.ql-indent-8:before {
    content: counter(list-8, lower-roman) ". "
}

.quill .ql-editor ol li.ql-indent-8 {
    counter-reset: list-9
}

.quill .ql-editor ol li.ql-indent-9 {
    counter-increment: list-9
}

.quill .ql-editor ol li.ql-indent-9:before {
    content: counter(list-9, decimal) ". "
}

.quill .ql-editor .ql-indent-1:not(.ql-direction-rtl) {
    margin-left: 3em
}

.quill .ql-editor li.ql-indent-1:not(.ql-direction-rtl) {
    margin-left: 4.5em
}

.quill .ql-editor .ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 3em
}

.quill .ql-editor li.ql-indent-1.ql-direction-rtl.ql-align-right {
    padding-right: 4.5em
}

.quill .ql-editor .ql-indent-2:not(.ql-direction-rtl) {
    margin-left: 6em
}

.quill .ql-editor li.ql-indent-2:not(.ql-direction-rtl) {
    margin-left: 7.5em
}

.quill .ql-editor .ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 6em
}

.quill .ql-editor li.ql-indent-2.ql-direction-rtl.ql-align-right {
    padding-right: 7.5em
}

.quill .ql-editor .ql-indent-3:not(.ql-direction-rtl) {
    margin-left: 9em
}

.quill .ql-editor li.ql-indent-3:not(.ql-direction-rtl) {
    margin-left: 10.5em
}

.quill .ql-editor .ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 9em
}

.quill .ql-editor li.ql-indent-3.ql-direction-rtl.ql-align-right {
    padding-right: 10.5em
}

.quill .ql-editor .ql-indent-4:not(.ql-direction-rtl) {
    margin-left: 12em
}

.quill .ql-editor li.ql-indent-4:not(.ql-direction-rtl) {
    margin-left: 13.5em
}

.quill .ql-editor .ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 12em
}

.quill .ql-editor li.ql-indent-4.ql-direction-rtl.ql-align-right {
    padding-right: 13.5em
}

.quill .ql-editor .ql-indent-5:not(.ql-direction-rtl) {
    margin-left: 15em
}

.quill .ql-editor li.ql-indent-5:not(.ql-direction-rtl) {
    margin-left: 16.5em
}

.quill .ql-editor .ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 15em
}

.quill .ql-editor li.ql-indent-5.ql-direction-rtl.ql-align-right {
    padding-right: 16.5em
}

.quill .ql-editor .ql-indent-6:not(.ql-direction-rtl) {
    margin-left: 18em
}

.quill .ql-editor li.ql-indent-6:not(.ql-direction-rtl) {
    margin-left: 19.5em
}

.quill .ql-editor .ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 18em
}

.quill .ql-editor li.ql-indent-6.ql-direction-rtl.ql-align-right {
    padding-right: 19.5em
}

.quill .ql-editor .ql-indent-7:not(.ql-direction-rtl) {
    margin-left: 21em
}

.quill .ql-editor li.ql-indent-7:not(.ql-direction-rtl) {
    margin-left: 22.5em
}

.quill .ql-editor .ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 21em
}

.quill .ql-editor li.ql-indent-7.ql-direction-rtl.ql-align-right {
    padding-right: 22.5em
}

.quill .ql-editor .ql-indent-8:not(.ql-direction-rtl) {
    margin-left: 24em
}

.quill .ql-editor li.ql-indent-8:not(.ql-direction-rtl) {
    margin-left: 25.5em
}

.quill .ql-editor .ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 24em
}

.quill .ql-editor li.ql-indent-8.ql-direction-rtl.ql-align-right {
    padding-right: 25.5em
}

.quill .ql-editor .ql-indent-9:not(.ql-direction-rtl) {
    margin-left: 27em
}

.quill .ql-editor li.ql-indent-9:not(.ql-direction-rtl) {
    margin-left: 28.5em
}

.quill .ql-editor .ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 27em
}

.quill .ql-editor li.ql-indent-9.ql-direction-rtl.ql-align-right {
    padding-right: 28.5em
}

.quill .ql-editor .ql-video {
    display: block;
    max-width: 100%
}

.quill .ql-editor .ql-video.ql-align-center {
    margin: 0 auto
}

.quill .ql-editor .ql-video.ql-align-right {
    margin: 0 0 0 auto
}

.quill .ql-editor .ql-bg-black {
    background-color: #000
}

.quill .ql-editor .ql-bg-red {
    background-color: #e60000
}

.quill .ql-editor .ql-bg-orange {
    background-color: #f90
}

.quill .ql-editor .ql-bg-yellow {
    background-color: #ff0
}

.quill .ql-editor .ql-bg-green {
    background-color: #008a00
}

.quill .ql-editor .ql-bg-blue {
    background-color: #06c
}

.quill .ql-editor .ql-bg-purple {
    background-color: #93f
}

.quill .ql-editor .ql-color-white {
    color: #fff
}

.quill .ql-editor .ql-color-red {
    color: #e60000
}

.quill .ql-editor .ql-color-orange {
    color: #f90
}

.quill .ql-editor .ql-color-yellow {
    color: #ff0
}

.quill .ql-editor .ql-color-green {
    color: #008a00
}

.quill .ql-editor .ql-color-blue {
    color: #06c
}

.quill .ql-editor .ql-color-purple {
    color: #93f
}

.quill .ql-editor .ql-font-serif {
    font-family: Georgia, Times New Roman, serif
}

.quill .ql-editor .ql-font-monospace {
    font-family: Monaco, Courier New, monospace
}

.quill .ql-editor .ql-size-small {
    font-size: .75em
}

.quill .ql-editor .ql-size-large {
    font-size: 1.5em
}

.quill .ql-editor .ql-size-huge {
    font-size: 2.5em
}

.quill .ql-editor .ql-direction-rtl {
    direction: rtl;
    text-align: inherit
}

.quill .ql-editor .ql-align-center {
    text-align: center
}

.quill .ql-editor .ql-align-justify {
    text-align: justify
}

.quill .ql-editor .ql-align-right {
    text-align: right
}

.quill .ql-editor.ql-blank:before {
    color: rgba(0, 0, 0, .6);
    content: attr(data-placeholder);
    font-style: italic;
    left: 15px;
    pointer-events: none;
    position: absolute;
    right: 15px
}
