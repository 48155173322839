.is-at-end {
    align-self: flex-end;
}
.is-at-start {
    align-self: flex-start;
}
.is-in-center {
    align-self: center;
}
.expands {
    flex-grow: 1;
}


@media screen and (max-width: 1023px) {
    .grows-for-touch {
        flex-grow: 1 !important;
    }
}
