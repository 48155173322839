.xero-checkbox {
    display: block;
    padding-left: 34px;
    position: relative;
    user-select: none;
    min-height: 24px;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & span {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        background-color: #e0e0e0;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
    }

    &:hover input ~ span {
        background-color: #cccccc;
    }

    &:active input ~ span {
        background-color: #aaaaaa;
    }

    & input:checked ~ span {
        background-color: $primary;
    }

    & span::after {
        content: '';
        position: absolute;
        display: none;
    }
    & input:checked ~ span::after {
        display: block;
    }

    & span::after {
        left: 8px;
        top: 1px;
        width: 8px;
        height: 16px;
        border: solid white;
        border-width: 0 3px 3px 0;
        transform: rotate(45deg);
    }

    & span.no-mark:after, .xero-select input:checked ~ span.no-mark:after {
        display: none !important;
    }
}


.xero-radio {
    display: block;
    padding-left: 34px;
    position: relative;
    user-select: none;
    min-height: 24px;

    & input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    & span {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 24px;
        height: 24px;
        border-color: transparent;
        border-radius: 12px;
        background-color: #eeeeee;
        font-size: 16px;
        text-align: center;
        font-weight: bold;
    }

    &:hover input ~ span {
        background-color: #cccccc;
    }

    &:active input ~ span {
        background-color: #aaaaaa;
    }

    & input:checked ~ span {
        background-color: $primary;
    }

    & span::after {
        content: '';
        position: absolute;
        display: none;
    }
    & input:checked ~ span::after {
        display: block;
    }

    & span::after {
        left: 6px;
        top: 6px;
        width: 12px;
        height: 12px;
        border: transparent;
        border-radius: 6px;
        background-color: white;
    }

    & span.no-mark:after, .xero-select input:checked ~ span.no-mark:after {
        display: none !important;
    }
}

.required {
    position: relative;

    &::before {
        position: absolute;
        top:0;
        left:-8px;
        font-weight: bold;
        color: red;
        content: '*';
    }
}
.is-horizontal .required {
    position: relative;

    &::after {
        position: absolute;
        top:0;
        right:-8px;
        font-weight: bold;
        color: red;
        content: '*';
    }
    &::before {
        content: '';
    }
}

@media only screen and (max-width: 768px) {
    .is-horizontal .required {

        &::after {
            content: '';
        }
        &::before {
            content: '*';
        }
    }
}

.single-button-form {
    padding: 0 !important;
    height: auto;
    overflow: hidden;
    display: inline-block;

    & .button {
        border: none !important;
        margin: -1px 0;
    }
}

.menu-list button {
    border: none;
    border-radius: 2px;
    color: $grey-dark;
    display: block;
    padding: 0.5em 0.75em;
    cursor: pointer;
    text-decoration: none;
    background: inherit;
    width: 100%;
    text-align: left;
    font-size: 1rem;
    line-height: 1.25;

    &:hover {
        background-color: whitesmoke;
        color: $grey-darker;
    }
}
