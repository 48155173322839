.cell {
    grid-column-start: auto;
    grid-column-end: auto;
    grid-row-start: auto;
    grid-row-end: auto;
}

.from-c-start, .from-c-1 {
    grid-column-start: 1;
}
.from-c-2 {
    grid-column-start: 2;
}
.from-c-3 {
    grid-column-start: 3;
}
.from-c-4 {
    grid-column-start: 4;
}
.from-c-5 {
    grid-column-start: 5;
}
.from-c-6 {
    grid-column-start: 6;
}
.from-c-7 {
    grid-column-start: 7;
}
.from-c-8 {
    grid-column-start: 8;
}
.from-c-9 {
    grid-column-start: 9;
}
.from-c-10 {
    grid-column-start: 10;
}
.from-c-11 {
    grid-column-start: 11;
}
.from-c-12 {
    grid-column-start: 12;
}
.from-c-end, .from-c-13 {
    grid-column-start: 13;
}



.to-c-start, .to-c-1 {
    grid-column-end: 1;
}
.to-c-2 {
    grid-column-end: 2;
}
.to-c-3 {
    grid-column-end: 3;
}
.to-c-4 {
    grid-column-end: 4;
}
.to-c-5 {
    grid-column-end: 5;
}
.to-c-6 {
    grid-column-end: 6;
}
.to-c-7 {
    grid-column-end: 7;
}
.to-c-8 {
    grid-column-end: 8;
}
.to-c-9 {
    grid-column-end: 9;
}
.to-c-10 {
    grid-column-end: 10;
}
.to-c-11 {
    grid-column-end: 11;
}
.to-c-12 {
    grid-column-end: 12;
}
.to-c-end, .to-c-13 {
    grid-column-end: 13;
}


.from-h-start, .from-h-1 {
    grid-row-start: 1;
}
.from-h-2 {
    grid-row-start: 2;
}
.from-h-3 {
    grid-row-start: 3;
}
.from-h-4 {
    grid-row-start: 4;
}
.from-h-5 {
    grid-row-start: 5;
}
.from-h-6 {
    grid-row-start: 6;
}
.from-h-7 {
    grid-row-start: 7;
}
.from-h-8 {
    grid-row-start: 8;
}
.from-h-9 {
    grid-row-start: 9;
}
.from-h-10 {
    grid-row-start: 10;
}
.from-h-11 {
    grid-row-start: 11;
}
.from-h-12 {
    grid-row-start: 12;
}
.from-h-end, .from-h-13 {
    grid-row-start: 13;
}


.to-h-start, .to-h-1 {
    grid-row-end: 1;
}
.to-h-2 {
    grid-row-end: 2;
}
.to-h-3 {
    grid-row-end: 3;
}
.to-h-4 {
    grid-row-end: 4;
}
.to-h-5 {
    grid-row-end: 5;
}
.to-h-6 {
    grid-row-end: 6;
}
.to-h-7 {
    grid-row-end: 7;
}
.to-h-8 {
    grid-row-end: 8;
}
.to-h-9 {
    grid-row-end: 9;
}
.to-h-10 {
    grid-row-end: 10;
}
.to-h-11 {
    grid-row-end: 11;
}
.to-h-12 {
    grid-row-end: 12;
}
.to-h-end, .to-h-13 {
    grid-row-end: 13;
}


.is-1-wide {
    grid-column-end: span 1;
}
.is-2-wide {
    grid-column-end: span 2;
}
.is-3-wide {
    grid-column-end: span 3;
}
.is-4-wide {
    grid-column-end: span 4;
}
.is-5-wide {
    grid-column-end: span 5;
}
.is-6-wide {
    grid-column-end: span 6;
}
.is-7-wide {
    grid-column-end: span 7;
}
.is-8-wide {
    grid-column-end: span 8;
}
.is-9-wide {
    grid-column-end: span 9;
}
.is-10-wide {
    grid-column-end: span 10;
}
.is-11-wide {
    grid-column-end: span 11;
}
.is-12-wide {
    grid-column-end: span 12;
}


.is-1-tall {
    grid-row-end: span 1;
}
.is-2-tall {
    grid-row-end: span 2;
}
.is-3-tall {
    grid-row-end: span 3;
}
.is-4-tall {
    grid-row-end: span 4;
}
.is-5-tall {
    grid-row-end: span 5;
}
.is-6-tall {
    grid-row-end: span 6;
}
.is-7-tall {
    grid-row-end: span 7;
}
.is-8-tall {
    grid-row-end: span 8;
}
.is-9-tall {
    grid-row-end: span 9;
}
.is-10-tall {
    grid-row-end: span 10;
}
.is-11-tall {
    grid-row-end: span 11;
}
.is-12-tall {
    grid-row-end: span 12;
}


@media screen and (max-width:1023px) {
    .grid .cell {
        grid-column-start: auto;
        grid-column-end: 1 span;
        grid-row-start: auto;
        grid-row-end: auto;
    }

    .grid .if-full-width-on-tablet {
        grid-column-end: 2 span;
    }
}
@media screen and (max-width:768px) {
    .grid .if-full-width-on-mobile {
        grid-column-end: 2 span;
    }
}
