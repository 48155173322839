@import url('https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Serif:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,700;1,400;1,700&display=swap');

$family-secondary: 'Antic Slab', 'PT Serif', serif;
$family-sans-serif: 'PT Sans', sans-serif;
//$family-sans-serif: 'Raleway', 'PT Sans', sans-serif;
$primary: #f38135;
$footer-background-color: #363636;
$menu-item-active-background-color: $primary;
$input-placeholder-color: rgba(#5b5b5b, .1);

@import '~bulma/bulma';
@import '~bulma-divider';

@import './vendor-styles/tooltip-styles';
@import './vendor-styles/quill-manual';

@import './xerocraft-styles/grid/xero-grid';

@import './xerocraft-styles/general/base';
@import './xerocraft-styles/general/modules';

@import './xerocraft-styles/specifics/all';


@import './xerocraft-styles/general/print';

//MODIFICATIONS TO LIBRARIES:
.v--modal-overlay {
    background: rgba(0, 0, 0, 0.6) !important;
}

body .ql-editor {
    overflow-y: inherit;
}
