.json-viewer-theme {
    background: #1c1c1c;
    white-space: nowrap;
    color: #ffffff;
    font-size: 14px;
    font-family: Consolas, Menlo, Courier, monospace;

    .jv-ellipsis {
        color: #ffffff;
        background-color: #696969;
        display: inline-block;
        line-height: 0.9;
        font-size: 0.9em;
        padding: 0px 4px 2px 4px;
        border-radius: 3px;
        vertical-align: 2px;
        cursor: pointer;
        user-select: none;
    }
    .jv-button { color: #49b3ff }
    .jv-key { color: #ffffff
    }
    .jv-item {
        &.jv-array { color: #ffffff
        }
        &.jv-boolean { color: #79d4ff
        }
        &.jv-function { color: #067bca }
        &.jv-number { color: #fc1e70 }
        &.jv-number-float { color: #fc1e70 }
        &.jv-number-integer { color: #fc1e70 }
        &.jv-object { color: #ffffff
        }
        &.jv-undefined { color: #e08331 }
        &.jv-string {
            color: #42b983;
            word-break: break-word;
            white-space: normal;
        }
    }
    .jv-code {
        .jv-toggle {
            &:before {
                padding: 0px 2px;
                border-radius: 2px;
            }
            &:hover {
                &:before {
                    background: #ffffff;
                }
            }
        }
    }
}
